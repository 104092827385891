import React, { useState } from 'react'
import { useClickOutside } from './../../hooks/useClickOutside'
import { ReactComponent as SelectIcon } from './../../assets/icons/select-icon.svg'

import './Select.scss'
import './../UI/Input/Input.scss'
import SelectItem from './selectItem/SelectItem'


const Select = ({
  list,
  name,
  label,
  placeholder,
  touched,
  errors,
  values,
  setValues,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const domNode = useClickOutside(() => {
    setIsOpen(false)
  });

  return (
    <div className={`select ${isOpen ? 'open' : ''}`}>
      <div className="select__label-wrap">
        <label className="select__label">
          <span>{label}</span>
        </label>
      </div>
      <div
        ref={domNode}
        className="select__container"
      >
        <div onClick={() => setIsOpen(prev => !prev)} className={`select__control${values[name] ? ' selected' : ''}${errors[name] && touched[name] ? ' error-field': ''}`}>
          <span>
            {values[name] || placeholder}
          </span>
          <SelectIcon className={'select__icon'} />
        </div>
        <div className="select__dropdown">
          <ul>
            {list.map(item => (
              <SelectItem
                key={item}
                values={values}
                setValues={setValues}
                name={name}
                id={item}
                value={item}
                errors={errors}
                touched={touched}
                setIsOpen={setIsOpen}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Select