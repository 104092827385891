import React from 'react';

import './Input.scss';

const Input = (props) => {

    const handleChangeValue = e => {
        if (props.incorrectData === true) {
            props.setIncorrectData(false)
        }
        props.touched[props.name] = false
        props.handleChange(e)
    }

    return (
        <div className={`input-field ${props.className}${props.touched[props.name] && props.errors[props.name] ? ' is-error' : ''}`}
        >
            { props.label ?
                <div className="input-field__inner">
                    <label htmlFor={props.id} className="input-field__label">
                        <span>{props.label}</span>
                    </label>
                </div>
                :
                null
            }
            {
                props.note &&
                <p className='input-field__note'>
                    {props.note}
                </p>
            }
            <input
                name={props.name}
                onChange={handleChangeValue}
                value={props.values[props.name]}
                type={props.type}
                id={props.id}
                className="input-field__input"
                placeholder={props.placeholder}
            />
        </div>
    );
}

export default Input;
