import { createSlice } from '@reduxjs/toolkit';

import { generateDescription, generateDescriptionFromFile } from './DescriptionsThunks';

export const initialState = {
    data: [], // request history
    downloadUrl: null,
    ozm_name: '',
    intermediateResult: '',
    name: '',
    loading: false,
    loadingGenerateDescr: false,
    error: false,
    errorStatus: null,
    isGetDescr: false,
};

const DescriptionsSlice = createSlice({
    name: 'descriptions',
    initialState,
    reducers: {
        setName: (state, { payload }) => {
            state.name = payload
        },
        removeError: (state) => {
            state.error = false;
            state.errorStatus = null;
        },
        clearData: (state) => {
            state.ozm_name = '';
            state.intermediateResult = '';
            state.downloadUrl = null;
        },
        hideDescr: (state) => {
            state.isGetDescr = false
        }
    },
    extraReducers: builder => {
        builder.addCase(generateDescription.pending, state => {
            state.error = false;
            state.loadingGenerateDescr = true;
        });
        builder.addCase(generateDescription.fulfilled, (state, { payload }) => {
            state.downloadUrl = null;
            state.ozm_name = payload.ozm_name;
            state.intermediateResult = JSON.parse(payload.debug_info).results;
            state.loadingGenerateDescr = false;
            state.isGetDescr = true;
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });
        builder.addCase(generateDescription.rejected, (state, { payload }) => {
            state.error = true;
            state.errorStatus = payload
            state.loadingGenerateDescr = false;
        });
        builder.addCase(generateDescriptionFromFile.pending, state => {
            state.error = false;
            state.loadingGenerateDescr = true;
        });
        builder.addCase(generateDescriptionFromFile.fulfilled, (state, { payload }) => {
            state.ozm_name = '';
            state.intermediateResult = '';
            state.downloadUrl = payload;
            state.loadingGenerateDescr = false;
            state.isGetDescr = true;
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });
        builder.addCase(generateDescriptionFromFile.rejected, (state, { payload }) => {
            state.error = true;
            state.errorStatus = payload
            state.loadingGenerateDescr = false;
        });
    },
});

export const DescriptionsReducer = DescriptionsSlice.reducer;
export const DescriptionsActions = {
    ...DescriptionsSlice.actions,
    generateDescription,
    generateDescriptionFromFile
};
