import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../services/api";
import TokenService from "../../services/tokenService";

export const generateDescription = createAsyncThunk(
  "descriptions/generate",
  async (values, { rejectWithValue }) => {
    return await instance
      .post("users/generate_normalized_description", values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      })
      .then((res) => res.data)
      .catch((e) => rejectWithValue(e.response.status));
  }
);

export const generateDescriptionFromFile = createAsyncThunk(
  "descriptions/generate-from-file",
  async (values, { rejectWithValue }) => {
    const data = new FormData();
    data.append("category_name", values.category_name.toLowerCase());
    data.append("llm_name", values.llm_name);
    data.append("file", values.file);

    return await instance
      .post("users/file/generate_normalized_descriptions", data, {
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
        responseType: "arraybuffer",
      })
      .then((res) => res.data)
      .then((data) => {
        var url = window.URL.createObjectURL(new Blob([data]));
        return url;
      })
      .catch((e) => rejectWithValue(e.response.status));
  }
);
