import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "../../hooks/useSelector";
import GenerateDescrForm, {
  SELECT_AI_OPTIONS,
} from "./../../components/forms/generateDescription/GenerateDescrForm";
import { ReactComponent as CopyIcon } from "./../../assets/icons/copy-icon.svg";
import { ReactComponent as CopiedIcon } from "./../../assets/icons/copied-icon.svg";
import sprite from "../../assets/images/sprite.svg";
import { ReactComponent as UploadIcon } from "./../../assets/icons/upload-icon.svg";
import Logotype from "../../assets/images/wee-logo.svg";
import Image from "../../assets/images/murr-generation.svg";
import { useActions } from "../../hooks/useActions";
import useAutosizeTextArea from "../../hooks/useAutosSize";

import "./GenerationDescriptions.scss";

export const startInitialState = {
  category: "",
  file: null,
  typeAI: SELECT_AI_OPTIONS[0],
};

const GenerationDescriptions = () => {
  const { ozm_name, intermediateResult, downloadUrl, isGetDescr } = useSelector(
    (state) => state.descriptions
  );
  const { clearData, hideDescr, logout } = useActions();
  const [isCopied, setIsCopied] = useState(false);

  const [generatedValue, setGeneratedValue] = useState("");
  const formRef = useRef();

  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, generatedValue);

  useEffect(() => {
    setGeneratedValue(ozm_name || "");
  }, [ozm_name, setGeneratedValue]);

  const initialStateGeneration = useMemo(
    () => ({
      ...startInitialState,
    }),
    []
  );

  const handleClearParams = () => {
    clearData();
    formRef?.current?.setValues((prev) => {
      prev.typeAI = SELECT_AI_OPTIONS[0];
      prev.category = "";
      prev.file = null;
      return {
        ...prev,
      };
    });
    hideDescr();
  };

  const handleLogout = () => {
    handleClearParams();
    logout();
  };

  const handleCopy = useCallback(() => {
    window.navigator.clipboard.writeText(textAreaRef.current.value || "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  }, []);

  const handleChangeTextArea = useCallback(
    (e) => {
      setGeneratedValue(e.target.value);
    },
    [setGeneratedValue]
  );

  return (
    <div className="generation">
      <div className="generation__wrap">
        <div className={`generation__left ${!isGetDescr ? "is--active" : ""}`}>
          <div className="generation__container-left">
            <div className="generation__wrapper-left">
              <div className="generation__top-left">
                <picture className="generation__logotype">
                  <img src={Logotype} alt="SOLLA" />
                </picture>
                <div
                  className="generation__close generation__close--mobile"
                  onClick={handleLogout}
                >
                  Выйти
                </div>
              </div>
              <GenerateDescrForm
                initialState={initialStateGeneration}
                formRef={formRef}
              />
            </div>
          </div>
        </div>
        <div className={`generation__right ${isGetDescr ? "is--active" : ""}`}>
          <div className="generation__container-right">
            <div className="generation__wrapper-right">
              <div
                className="generation__close generation__close--desktop"
                onClick={handleLogout}
              >
                Выйти
              </div>
              <div className="generation__top-right">
                <button
                  className="generation__back"
                  onClick={() => hideDescr()}
                >
                  <svg className="generation__back-icon">
                    <use href={sprite + "#icon-back"}></use>
                  </svg>
                  <span>Назад</span>
                </button>
              </div>
              <div className="generation__body-right">
                {ozm_name ? (
                  <>
                    <div className="generation__result-title">
                      Название товара
                    </div>
                    <div className={"generation__main border-result"}>
                      <div className="generation__result ">
                        <textarea
                          ref={textAreaRef}
                          className="generation__description"
                          onChange={handleChangeTextArea}
                          value={generatedValue}
                        />
                        <button onClick={handleCopy} className="copy-btn">
                          {!isCopied ? <CopyIcon /> : <CopiedIcon />}
                        </button>
                      </div>
                    </div>
                    {intermediateResult && (
                      <>
                        <div className="generation__result-title intermediate">
                          Промежуточные ответы
                        </div>
                        <div className={"generation__main border-result"}>
                          <p className="generation__description">
                            {intermediateResult}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : downloadUrl ? (
                  <>
                    <div className="generation__result-title file">
                      Наименования созданы!
                    </div>
                    <div className={"generation__main border-result"}>
                      <div className="generation__result file">
                        <UploadIcon />
                        <div className="btn_wrap download-link_wrap">
                          <a
                            href={downloadUrl}
                            download={"res.xlsx"}
                            className={"btn download-link"}
                          >
                            Скачать файл
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={"generation__main"}>
                    <div className="generation__preview">
                      <picture className="generation__img">
                        <img src={Image} alt="" />
                      </picture>
                      {/* <p className="generation__help">
                        Здесь будет нормализованное наименование МТР...
                      </p> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerationDescriptions;
