import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../services/api';
import TokenService from '../../services/tokenService';

export const getAuth = createAsyncThunk(
    'auth/me',
    async (_, { rejectWithValue }) => {
        return await instance.post('auth/me', {}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${TokenService.getLocalAccessToken()}`,
            }
        })
            .then(res => {
                var _tmr = window._tmr || (window._tmr = []);
                _tmr.push({id: "3317476",  type: "pageView", start: (new Date()).getTime(), userid: res.data.email })
                return res.data
            })
            .catch(e => rejectWithValue(e.message))
    }
);

export const signIn = createAsyncThunk(
    'auth/sign-in',
    async ({ values, setIncorrectData, navigate }, { rejectWithValue}) => {
            return await instance.post(
                'auth/sign_in',
                values,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then(res => {
                    var _tmr = window._tmr || (window._tmr = []);
                    _tmr.push({ id: '3317476', type: 'reachGoal', goal: 'mt_login', userid: values.username  });
                    return res.data
                })
                .catch(e => {
                    if (e.response.status === 400) {
                        setIncorrectData(true)
                    }
                    return rejectWithValue(e.message)
                })
    }
)

export const refreshToken = createAsyncThunk(
    'auth/refresh',
    async (_, { rejectWithValue }) => {
        await instance.post('auth/refresh', {}, {
            headers: {
                'content-type': 'application/json',
                "Authorization": `Bearer ${TokenService.getLocalRefreshToken()}`,
            }
        })
            .then(res => {
                const { access_token } = res.data
                TokenService.updateLocalAccessToken(access_token)
            })
            .catch (e => {
                TokenService.removeUser()
                return rejectWithValue(e.message)
            })
    }
)
