import React, { useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../UI/Button/Button";
import { useSelector } from "../../../hooks/useSelector";
import { useActions } from "../../../hooks/useActions";
import Select from "../../select/Select";
import UploadFile from "../../UploadFile/UploadFile";

export const SELECT_CATEGORIES_OPTIONS = ["Cases"];

export const SELECT_AI_OPTIONS = ["gpt-4-0125-preview"];

const validation = {
  category: Yup.string().max(110).required("Обязательное поле"),
  file: Yup.mixed()
    .nullable()
    .test({
      name: "file-testing",
      test: (value) => !!value,
      message: "Добавьте файл",
    }),
};

const yupValidationScheme = Yup.object().shape({ ...validation });

const GenerateDescrForm = ({ initialState, formRef }) => {
  const { generateDescription, generateDescriptionFromFile } = useActions();
  const { error, errorStatus, loadingGenerateDescr } = useSelector(
    (state) => state.descriptions
  );

  const inputFileRef = useRef(null);

  const handleSubmit = async (
    values,
    { setSubmitting, errors, setStatus, resetForm }
  ) => {
    if (values.file) {
      const dto = {
        category_name: values.category,
        llm_name: values.typeAI,
        file: values.file,
      };
      await generateDescriptionFromFile(dto);
    } else {
      const dto = {
        category_name: values.category,
        llm_name: values.typeAI,
      };
      await generateDescription(dto);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialState}
      validationSchema={yupValidationScheme}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        values,
        setValues,
        isSubmitting,
        status,
        errors,
        touched,
        handleChange,
      }) => {
        return (
          <Form className="generation__body-left">
            <div className="generation__block">
              {/* <div className="generation__input-block">
                <Select
                  list={SELECT_AI_OPTIONS}
                  name={"typeAI"}
                  id={"generation-goods-type-AI"}
                  label={"Тип ИИ"}
                  placeholder={"Выберите тип"}
                  touched={touched}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  isDisplayError={true}
                />
              </div> */}
              <div className="generation__input-block">
                <Select
                  list={SELECT_CATEGORIES_OPTIONS}
                  name={"category"}
                  id={"generation-goods-category"}
                  label={"Категория товара"}
                  placeholder={"Выберите категорию"}
                  touched={touched}
                  errors={errors}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  isDisplayError={true}
                />
              </div>
              {/* <div className="generation__input-block">
                <Textarea
                  name={"name"}
                  className={"generation__input"}
                  id={"generation-goods-name"}
                  label={"Название товара"}
                  placeholder={"Ввести название"}
                  type={"text"}
                  touched={touched}
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  isDisplayError={true}
                />
              </div> */}
              <div className="generation__input-block">
                <UploadFile
                  values={values}
                  setValues={setValues}
                  inputFileRef={inputFileRef}
                />
              </div>
            </div>
            {/* <div className="template__link-wrap">
              <a className="download__file-template" download href="/mtrs.xlsx">
                Скачать шаблон файла
              </a>
            </div> */}
            <div className="form__footer">
              {errors.file && !values.file && (
                <div className="error request-error">Добавьте файл</div>
              )}
              {error && (errorStatus === 429 || errorStatus === 404) && (
                <div className="error request-error">
                  Ошибка запроса. Попробуйте еще раз
                </div>
              )}
              {loadingGenerateDescr && (
                <div className="loading-text">
                  Время создания 1000 описаний ~5 минут
                </div>
              )}
              <Button
                className={"generation__btn"}
                title={"Создать описание"}
                type="submit"
                isLoading={loadingGenerateDescr}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GenerateDescrForm;
