import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import GenerationDescriptions from "./pages/GenerationDescriptions/GenerationDescriptions";
import SignIn from "./pages/SignIn/SignIn";
import { useSelector } from "./hooks/useSelector";
import { useActions } from "./hooks/useActions";
import AppLoadingScreen from "./components/AppLoadingScreen/AppLoadingScreen";
import TokenServiceInstance from "./services/tokenService";

const App = () => {
  const { isAuth, appLoading } = useSelector((state) => state.auth);
  const { getAuth, removeAppLoading } = useActions();

  useEffect(() => {
    if (TokenServiceInstance.getLocalAccessToken()) {
      getAuth();
    } else removeAppLoading();
    // eslint-disable-next-line
  }, []);

  return appLoading ? (
    <AppLoadingScreen />
  ) : (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Navigate to={isAuth ? "generation" : "sign-in"} replace />
            }
          />
          {isAuth ? (
            <Route path="generation" element={<GenerationDescriptions />} />
          ) : (
            <Route path="sign-in" element={<SignIn />} />
          )}
          <Route
            path="*"
            element={<Navigate to={isAuth ? "generation" : "sign-in"} />}
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
