import React from "react";
import { ReactComponent as LoaderIcon } from "./../../../assets/icons/loader.svg";

import "./Button.scss";

const Button = ({
  className,
  onClick,
  title,
  type = "button",
  disabled = false,
  isLoading = false,
}) => {
  return (
    <div className="btn_wrap">
      {!isLoading ? (
        <button
          className={"btn " + className}
          onClick={onClick}
          disabled={disabled}
          type={type}
        >
          <span>{title}</span>
        </button>
      ) : (
        <LoaderIcon className="loader-icon" />
      )}
    </div>
  );
};

export default Button;
