import React from "react";
import Logotype from "../../assets/images/wee-logo.svg";
import Image from "../../assets/images/murr-door.svg";
import SignInForm from "../../components/forms/SignIn/SignInForm";

import "./SignIn.scss";

const SignIn = () => {
  return (
    <div className="signin">
      <div className="auth">
        <div className="auth__wrapper">
          <div className="auth__left">
            <div className="auth__container">
              <div className="auth__top">
                <picture className="auth__logotype">
                  <img src={Logotype} alt="SOLLA" />
                </picture>
                <p className="auth__promo auth__promo--mobile">
                  {"Создавайте описание товаров за несколько секунд"}
                </p>
              </div>
              <div className="auth__body">
                <h1 className="auth__heading">{"Вход"}</h1>
                <SignInForm />
              </div>
            </div>
          </div>
          <div className="auth__right">
            {/* <p className="auth__promo">
              {"Создавайте описание товаров за несколько секунд"}
            </p> */}
            <picture className="auth__img">
              <img src={Image} alt="" />
            </picture>
            <div className="auth__decor auth__decor--first"></div>
            <div className="auth__decor auth__decor--second"></div>
            <div className="auth__decor auth__decor--third"></div>
            <div className="auth__decor auth__decor--fourth"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
