import { createSlice } from '@reduxjs/toolkit';
import TokenServiceInstance from '../../services/tokenService';

import { getAuth, signIn, refreshToken } from './AuthThunks';

export const initialState = {
    isAuth: false,
    email: null,
    access_token: null,
    refresh_token: null,
    isOpenModalAfterResPass: false,
    isOpenModalAfterConfirmEmail: false,
    loading: false,
    appLoading: true,
    confirmEmailLoading: true,
    error: false,
};

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        removeAppLoading: (state) => {
            state.appLoading = false;
        },
        getAuthInfo: (state) => {
            state.isAuth = !!TokenServiceInstance.getUser()
            state.appLoading = false
        },
        logout: (state) => {
            TokenServiceInstance.removeUser()
            state.isAuth = false;
            state.email = null;
            state.access_token = null;
            state.refresh_token = null;
        }
    },
    extraReducers: builder => {
        builder.addCase(getAuth.pending, state => {
            state.error = false;
            state.appLoading = true;
        });
        builder.addCase(getAuth.fulfilled, (state, { payload }) => {
            state.isAuth = true;
            state.access_token = TokenServiceInstance.getLocalAccessToken();
            state.email = payload.email
            state.appLoading = false;
        });
        builder.addCase(getAuth.rejected, (state, { payload }) => {
            state.error = true;
            state.appLoading = false;
        });
        builder.addCase(signIn.pending, state => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(signIn.fulfilled, (state, { payload }) => {
            TokenServiceInstance.setUser(payload)
            state.access_token = payload.access_token;
            state.refresh_token = payload.refresh_token;
            state.isAuth = true;
            state.loading = false;
        });
        builder.addCase(signIn.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
    },
});

export const AuthReducer = AuthSlice.reducer;
export const AuthActions = {
    ...AuthSlice.actions,
    getAuth,
    signIn,
    refreshToken,
};
