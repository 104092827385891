import React from 'react'
import './../Select.scss'

const SelectItem = ({
  name,
  value,
  id,
  values,
  setValues,
  touched,
  setIsOpen
}) => {

  const handleChangeValue = (e) => {
    touched[name] = false
    if (values[name] !== e.target.value) {
      setValues(prev => ({
        ...prev,
        [name]: e.target.value
      }))
    }
  }

  const handleCloseDropdown = () => {
    setIsOpen(false)
  }

  return (
    <li className="dropdown__item">
      <input type={'radio'} name={name} id={id} value={value} onChange={handleChangeValue} className='hidden' />
      <label htmlFor={id} className="dropdown__item-inner" onClick={handleCloseDropdown}>
        {value}
      </label>
    </li>
  )
}

export default SelectItem