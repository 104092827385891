import React, { useCallback } from 'react'
import { ReactComponent as UploadIcon } from './../../assets/icons/upload-icon.svg'
import { ReactComponent as CheckedIcon } from './../../assets/icons/upload-checked.svg'
import { ReactComponent as DeleteFile } from './../../assets/icons/delete-file.svg'
import { FileUploader } from "react-drag-drop-files";

import './UploadFile.scss'

const fileTypes = ["XLSX"]

const UploadFile = ({ inputFileRef, values, setValues }) => {

  const handleChange = useCallback(file => {
    setValues((prev) => ({
      ...prev,
      file: file
    }))
  }, [setValues])

  const handleDeleteFile = useCallback((e) => {
    setValues((prev) => ({
      ...prev,
      file: null,
    }))
  }, [setValues])

  return (
    <div className="upload-file_wrap">
      <input name="upload-file" type="file" className="hidden-input" />
      <label className="field-label">
        Загрузить файл с товарами
      </label>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        children={
          <label htmlFor='upload-file' className="upload-file">
            <span>
              {
                !values.file ?
                'Перетащите сюда файл' :
                'Файл загружен'
              }
            </span>
            {
              !values.file ?
              <UploadIcon /> :
              <CheckedIcon />
            }
            <div className="upload-file__footer">
              <button className="footer-btn add-btn">
                {
                  !values.file ?
                  'Добавить вложение' :
                  'Заменить вложение'
                }
              </button>
            </div>
          </label>
        }
      />
      {
        !!values.file &&
        <div className="uploaded-file">
          <div className="uploaded-file__content">
            <span>{values.file.name}</span>
            <button onClick={handleDeleteFile} className="delete-btn">
              <DeleteFile />
            </button>
          </div>
        </div>
      }
    </div>
  )
}

export default UploadFile